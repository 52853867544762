// Import variables first
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";
@import "custom";

// Animations
@keyframes FadeAnim {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

// Utility Classes
.blurry-text {
   color: transparent;
   // font-size: 0.688rem !important;
   text-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}

// Custom Component Styles
.btn-primary {
   background: linear-gradient(to right, $primary, $primary-dark);
   border: none;

   &:hover {
      background: linear-gradient(to right, $primary-dark, $primary);
   }
}

.card {
   border: none;
   border-radius: $border-radius-md;
   box-shadow: $shadow-sm;
   transition: transform 0.2s, box-shadow 0.2s;
   background-color: $card-bg;

   &:hover {
      transform: translateY(-2px);
      box-shadow: $shadow-md;
   }

   .card-header {
      background-color: transparent;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
   }
}

// Table styles
.table {
   --cui-table-bg: #{$card-bg};
   --cui-table-color: #{$dark};
   --cui-table-border-color: #{$light};

   th {
      font-size: 0.875rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      color: $muted;
   }

   td {
      vertical-align: middle;
   }

   tbody tr:hover {
      background-color: rgba($primary, 0.02);
   }
}

.accent-bg-color {
   background-color: rgba(0, 0, 0, 0.02);
}

.small {
   font-size: 0.875rem;
}

.cursor-pointer {
   cursor: pointer;
}

// Badge styles
.badge {
   padding: 0.5em 0.75em;
   border-radius: 50rem;
   font-weight: 500;

   &.bg-primary {
      background: linear-gradient(to right, $primary, $primary-dark) !important;
   }

   &.badge-purple {
      background-color: $accent;
      color: white;
   }
}

// Navigation
.sidebar {
   background-color: $dark;

   .nav-link {
      color: $light;

      &:hover {
         background-color: rgba(255, 255, 255, 0.1);
      }

      &.active {
         background-color: $primary;
      }
   }
}

// Header
.header {
   background-color: $card-bg;
   border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

// Links
a {
   color: $primary;

   &:hover {
      color: $primary-dark;
   }
}

// Spinner
.spinner-border {
   color: $primary;
}

// Form controls
.form-control:focus {
   border-color: $primary-light;
   box-shadow: 0 0 0 0.25rem rgba($primary, 0.25);
}

// Responsive tables
@media (max-width: 767px) {
   .table-responsive .dropdown-menu {
      position: static !important;
   }
}

@media (min-width: 768px) {
   .table-responsive {
      overflow: inherit;
   }
}

// Product logo
.product-envato-logo {
   background-color: rgb(60, 75, 100);
   border-radius: 20px;
}

// Sales Table Styles
.sales-card {
   background: $card-bg;
   border-radius: $border-radius-lg;
   box-shadow: $shadow-sm;
   transition: all 0.3s ease;

   &:hover {
      box-shadow: $shadow-md;
   }

   .sales-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 1.25rem;
      border-bottom: 1px solid rgba($dark, 0.05);

      .sales-title {
         display: flex;
         align-items: center;
         font-weight: 500;
         margin: 0;
         font-size: 1rem;

         .date-badge {
            color: $muted;
            font-size: 0.875rem;
            font-weight: 400;
            margin-left: 0.5rem;
         }

         .count-badge {
            padding: 0.25em 0.5em;
            font-size: 0.75em;
            background: linear-gradient(to right, $primary, $primary-dark);
         }
      }

      .current-time {
         display: flex;
         align-items: center;
         gap: 0.5rem;
         color: $muted;
         font-size: 0.875rem;
         font-weight: 500;

         .clock-icon {
            color: $primary;
            font-size: 1rem;
         }

         span {
            font-family: monospace;
            letter-spacing: 0.5px;
         }
      }
   }
}

.sales-table {
   margin-bottom: 0;

   th {
      background: rgba($light, 0.5);
      font-size: 0.688rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      // padding: 0.75rem 1rem;
      border-bottom: none;
   }

   .sales-row {
      font-size: 13px;
      transition: all 0.2s ease;

      &:hover {
         background-color: rgba($primary, 0.02);
      }

      td {
         // padding: 0.75rem 1rem;
         vertical-align: middle;
      }
   }

   .product-thumbnail {
      width: 70px;
      height: auto;
      max-height: 50px;
      object-fit: contain;


   }

   .product-details {
      .product-name {
         font-size: 12px;
         margin-bottom: 0.2rem;
      }

      .sale-info {
         font-size: 0.813rem;

         .order-id {
            display: block;
            margin-bottom: 0.2rem;
         }

         .location-info {
            display: flex;
            align-items: center;
            gap: 0.35rem;
            margin: 0.35rem 0;

            .country-name {
               color: $muted;
               font-size: 0.688rem;
            }
         }

         .badges {
            display: flex;
            gap: 0.35rem;
            margin-top: 0.35rem;
         }
      }
   }

   .sale-time {
      .time {
         font-size: 12px;
         // font-weight: 500;
      }

      .time-ago {
         font-size: 0.688rem;
      }
   }

   .sale-amount {
      .amount-wrapper {
         display: inline-flex;
         align-items: center;
         gap: 0.5rem;

         .amount {
            font-size: 12px;
            // font-weight: 500;
         }

         .visibility-toggle {
            font-size: 0.813rem;
         }
      }
   }

   .total-row {
      background-color: rgba($light, 0.5);
      font-weight: 600;

      td {
         padding: 0.75rem 1rem;
      }

      .total-amount {
         display: inline-flex;
         align-items: center;
         gap: 0.5rem;

         .amount {
            font-size: 1rem;
            font-weight: 500;
         }
      }
   }
}

.empty-sales-state {
   padding: 4rem 2rem;
   text-align: center;

   img {
      opacity: 0.5;
      margin-bottom: 1rem;
   }
}

// Badge Styles
.sale-type-badge {
   font-size: 0.55rem;
   font-weight: 400;
   padding: 0.25em 0.5em;
}

.sales-count-badge {
   @extend .sale-type-badge;
   background: linear-gradient(to right, $primary, $primary-dark);
}

// Estimate styles
.accent-bg-color {
   background-color: rgba($primary, 0.2);
}

.amount {
   .blurry-text {
      color: transparent;
      font-size: 0.575rem;
      text-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
   }

   .toggle-balance {
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
         color: $primary;
      }
   }
}

.list-group-item {
   padding: 1rem;
   border-color: rgba($dark, 0.05);

   &:last-child {
      font-weight: 500;
   }
}

.estimate-list {
   font-size: 13px !important;

   .list-group-item {
      padding: 0.75rem 1rem;
      border-color: rgba($dark, 0.05);

      &:last-child {
         font-weight: 500;
      }

      .label {
         color: $dark;
      }

      .value {
         color: $muted;
      }
   }
}

// Year dropdown styles
.dropdown-toggle {
   cursor: pointer;
   font-size: 0.875rem;
   color: $muted;

   &:hover {
      color: $primary;
   }
}

.dropdown-menu {
   min-width: 100px;
   font-size: 0.875rem;

   .dropdown-item {
      padding: 0.5rem 1rem;

      &:hover {
         background-color: rgba($primary, 0.05);
      }

      &.active {
         background-color: $primary;
         color: white;
      }
   }
}

// Feature showcase styles
.features-section {
   position: relative;
   background: linear-gradient(to bottom, #fff, #f8f9fa);

   &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: linear-gradient(to right, transparent, rgba($dark, 0.1), transparent);
   }
}

.feature-icon-wrapper {
   width: 80px;
   height: 80px;
   border-radius: 50%;
   background: rgba($primary, 0.1);
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0 auto;

   .icon {
      font-size: 2rem;
      color: $primary;
   }
}

.feature-list {
   text-align: left;
   margin: 0 auto;
   max-width: 80%;
}

.feature-image-wrapper {
   position: relative;
   border-radius: $border-radius-lg;
   overflow: hidden;
   transition: transform 0.3s ease;
   margin-top: 1rem;

   &:hover {
      transform: translateY(-5px);

      .screenshot-overlay::after {
         opacity: 0;
      }

      .feature-screenshot {
         transform: scale(1.02);
      }
   }

   .screenshot-overlay {
      position: relative;

      &::after {
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         background: linear-gradient(to bottom,
               rgba(255, 255, 255, 0) 0%,
               rgba(255, 255, 255, 0.02) 50%,
               rgba(255, 255, 255, 0.1) 100%);
         transition: opacity 0.3s ease;
      }
   }

   .feature-screenshot {
      width: 100%;
      height: auto;
      transition: transform 0.3s ease;
      border: 1px solid rgba($dark, 0.1);
   }
}

// Auth styles
.auth-logo-wrapper {
   width: 80px;
   height: 80px;
   margin: 0 auto;
   background: rgba($primary, 0.1);
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;

   .auth-logo {
      filter: brightness(0) saturate(100%) invert(62%) sepia(14%) saturate(1267%) hue-rotate(40deg) brightness(89%) contrast(89%);
   }
}

.auth-button {
   display: block;
   background: #82b440; // Envato green
   color: white;
   text-decoration: none;
   padding: 0.75rem 1.5rem;
   border-radius: $border-radius;
   transition: all 0.3s ease;
   border: none;
   box-shadow: 0 2px 4px rgba(130, 180, 64, 0.2); // Envato green shadow

   &:hover {
      background: #75a439; // Slightly darker Envato green
      transform: translateY(-2px);
      color: white;
      box-shadow: 0 4px 8px rgba(130, 180, 64, 0.3);
   }

   .auth-button-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
   }

   .auth-button-icon {
      filter: brightness(0) invert(1);
      width: 24px;
      height: 24px;
   }

   .auth-button-text {
      font-weight: 600;
      font-size: 0.95rem;
      letter-spacing: 0.2px;
   }
}

.loading-animation {
   max-width: 160px;
   margin: 0 auto;
}

.loading-status {
   background: rgba($success, 0.1);
   padding: 0.75rem;
   border-radius: $border-radius;
   margin-bottom: 1rem;

   span {
      font-size: 0.875rem;
   }
}

// Split Layout styles
.feature-bullet {
   width: 48px;
   height: 48px;
   border-radius: 50%;
   background: rgba($primary, 0.1);
   display: flex;
   align-items: center;
   justify-content: center;
   flex-shrink: 0;

   svg {
      width: 24px;
      height: 24px;
   }
}

.features-preview {
   max-width: 500px;
   margin: 0 auto;

   @media (min-width: 992px) {
      margin: 0;
   }

   h5 {
      font-size: 1.1rem;
      color: $secondary;
   }

   p {
      font-size: 0.9rem;
   }
}

.login-card-wrapper {
   position: relative;

   &::before {
      content: '';
      position: absolute;
      top: 15px;
      left: -15px;
      right: 15px;
      bottom: -15px;
      background: rgba($primary, 0.1);
      border-radius: $border-radius-lg;
      z-index: -1;
   }
}

// Update existing auth styles
.auth-button {
   margin-top: 1rem;
   text-align: center;
}

.auth-logo-wrapper {
   background: rgba($primary, 0.15);
}

// Product info styles
.product-info {
   padding-top: 2rem;
   border-top: 1px solid rgba($dark, 0.05);
   margin-top: 2rem;

   .info-badge {
      .badge {
         font-weight: 500;
         padding: 0.5em 1em;
         font-size: 0.85rem;

         svg {
            width: 12px;
            height: 12px;
         }
      }
   }

   .company-info {
      .company-link {
         color: $secondary;
         text-decoration: none;
         font-weight: 500;
         padding: 0.4rem 0.8rem;
         border-radius: $border-radius;
         background: rgba($primary, 0.05);
         font-size: 0.9rem;
         transition: all 0.2s ease;

         &:hover {
            background: rgba($primary, 0.1);
            color: darken($secondary, 10%);
         }
      }
   }

   .portfolio-link {
      .envato-portfolio-link {
         display: inline-flex;
         align-items: center;
         text-decoration: none;
         color: #82b440;
         font-weight: 500;
         padding: 0.4rem 0.8rem;
         font-size: 0.9rem;
         border-radius: $border-radius;
         transition: all 0.2s ease;

         img {
            transition: transform 0.2s ease;
         }

         &:hover {
            background: rgba(#82b440, 0.05);

            img {
               transform: scale(1.1);
            }
         }
      }
   }
}

@media (max-width: 768px) {
   .sales-table {
      /* Use a specific class for today's sales table */
      width: 100%;
      /* Make the table take full width */
      font-size: 13px;
      /* Reduce font size for mobile */
   }

   .sales-table th,
   .sales-table td {
      /* Target headers and cells specifically */
      padding: 8px;
      /* Adjust padding for smaller screens */
      word-wrap: break-word;
      /* Allow text to wrap within cells */
   }
}

// Trending styles
.media {
  display: flex;
  align-items: start;
  
  .media-body {
    flex: 1;
  }
}

.bg-light {
  background-color: rgb(246, 249, 251) !important;
}

.border-start.border-primary {
  border-left: 1px solid #6cb2eb !important;
}